"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineLoginPage = exports.LoginPage = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/webapp-common/lib-es2015");
const apis_1 = require("./apis");
const lib_es2015_3 = require("@hodol/dom-helper/lib-es2015");
class LoginPage extends HTMLElement {
    constructor() {
        super();
        this.serverProperties = (0, lib_es2015_2.getServerProps)();
        this.apis = new apis_1.default();
        this.redirectUrl = '';
        this.setupDirectLoginForm = () => {
            const directLoginEmailInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this.directLoginForm, 'input.email');
            const directLoginPasswordInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this.directLoginForm, 'input.password');
            const directLoginBtn = (0, lib_es2015_3.getQuerySelectOrThrowError)(this.directLoginForm, 'button.login-btn');
            directLoginEmailInput.addEventListener('keydown', (e) => {
                if (e.key === 'Enter')
                    void this.login(directLoginEmailInput.value, directLoginPasswordInput.value);
            });
            directLoginPasswordInput.addEventListener('keydown', (e) => {
                if (e.key === 'Enter')
                    void this.login(directLoginEmailInput.value, directLoginPasswordInput.value);
            });
            directLoginBtn.addEventListener('click', () => {
                void this.login(directLoginEmailInput.value, directLoginPasswordInput.value);
            });
        };
        this.setupOauthLoginForm = () => __awaiter(this, void 0, void 0, function* () {
            const btnContainer = (0, lib_es2015_3.getQuerySelectOrThrowError)(this.oauthLoginForm, 'div.oauth-btn-container');
            const oauthContexts = yield this.apis.getOauthContexts();
            for (const resourceServer in oauthContexts) {
                const oauthContext = oauthContexts[resourceServer];
                btnContainer.appendChild((0, lib_es2015_3.buildNodeByOuterHTML)(`<a href="${oauthContext}"><button>${resourceServer}</button></a>`));
            }
        });
        this.login = (id, password) => __awaiter(this, void 0, void 0, function* () {
            if (id === '') {
                alert(`put email`);
                return;
            }
            if (password === '') {
                alert(`put password`);
                return;
            }
            const apiResp = yield this.apis.login({ id, password });
            if (apiResp === null)
                return;
            if (this.redirectUrl === '')
                return;
            location.href = this.redirectUrl;
        });
        this.getUserName = (isFullName) => {
            var _a, _b;
            if (!(0, lib_es2015_2.isLogin)(this.serverProperties))
                return 'Guest';
            const member = this.serverProperties.member;
            if ((0, lib_es2015_2.isDirectLogin)(this.serverProperties)) {
                return `${this.serverProperties.globalIdentifier.identifier}`;
            }
            else if ((0, lib_es2015_2.isOauthLogin)(this.serverProperties)) {
                if (!isFullName)
                    return member.nick;
                return `${(_a = member.pre_title) !== null && _a !== void 0 ? _a : ''}${member.nick}${(_b = member.post_title) !== null && _b !== void 0 ? _b : ''}`;
            }
            throw Error(`Login but neither direct login nor oauth login, impossible!`);
        };
        console.debug('LoginPage constructed!');
    }
    connectedCallback() {
        var _a, _b;
        this.redirectUrl = (_a = this.getAttribute('redirect_url')) !== null && _a !== void 0 ? _a : '';
        if ((0, lib_es2015_2.isLogin)(this.serverProperties)) {
            this.innerHTML = `
        <div class="main">
          <div class="login-message">You have already logged in</div>
          <div class="user-control">
            <div class="user-name">${this.getUserName(true)}</div>
            <button class="edit-user">User Info</button>
            <button class="logout">Logout</button>
          </div>
        </div>`;
            const editUserBtn = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'button.edit-user');
            editUserBtn.addEventListener('click', () => {
                window.location.href = `/web/edit?redirect_url=${this.redirectUrl}`;
            });
            const logoutBtn = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'button.logout');
            logoutBtn.addEventListener('click', () => {
                void (() => __awaiter(this, void 0, void 0, function* () {
                    const apiResp = yield this.apis.logout();
                    if (apiResp === null)
                        return;
                    if (this.redirectUrl === '')
                        return;
                    location.href = this.redirectUrl;
                }))();
            });
        }
        else {
            const urlParams = new URLSearchParams(window.location.search);
            const loginType = (_b = urlParams.get('login_type')) !== null && _b !== void 0 ? _b : 'both';
            this.innerHTML = `
        <div class="main">
          <div class="login-form direct-login">
            <div>
              <input class="email" type="email" placeholder="E-mail"/>
            </div>
            <div>
              <input class="password" type="password" placeholder="Password"/>
            </div>
            <button class="login-btn">Login</button>
          </div>
          <hr class="direct-login oauth-login" />
          <div class="login-form oauth-login">
            <div>OAuth Logins</div>
            <div class="oauth-btn-container"></div>
          </div>
        </div>`;
            this.directLoginForm = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'div.login-form.direct-login');
            this.oauthLoginForm = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'div.login-form.oauth-login');
            const hr = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'hr');
            switch (loginType) {
                case 'direct':
                    this.setupDirectLoginForm();
                    this.oauthLoginForm.style.setProperty('display', 'none');
                    hr.style.setProperty('display', 'none');
                    break;
                case 'oauth':
                    void this.setupOauthLoginForm();
                    this.directLoginForm.style.setProperty('display', 'none');
                    hr.style.setProperty('display', 'none');
                    break;
                case 'both':
                    this.setupDirectLoginForm();
                    void this.setupOauthLoginForm();
                    break;
                default:
                    throw Error(`Unsupported login type: ${loginType}`);
            }
        }
    }
}
exports.LoginPage = LoginPage;
const defineLoginPage = (name) => {
    (0, lib_es2015_1.defineIfUndefined)(name, LoginPage);
};
exports.defineLoginPage = defineLoginPage;
