"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineViews = exports.routes = void 0;
const home_page_1 = require("../main/home-page");
const login_page_1 = require("../login-page/login-page");
const logout_page_1 = require("../logout-page/logout-page");
const edit_user_1 = require("../edit-user/edit-user");
exports.routes = [
    {
        path: '/web',
        tag: 'home-page',
        pathParamNames: [],
        queryParamNames: [],
    },
    {
        path: `/web/login`,
        tag: 'login-page',
        pathParamNames: [],
        queryParamNames: ['redirect_url'],
    },
    {
        path: `/web/logout`,
        tag: 'logout-page',
        pathParamNames: [],
        queryParamNames: ['redirect_url'],
    },
    {
        path: `/web/edit`,
        tag: 'edit-user',
        pathParamNames: [],
        queryParamNames: ['redirect_url'],
    },
];
const defineViews = () => {
    (0, login_page_1.defineLoginPage)('login-page');
    (0, logout_page_1.defineLogoutPage)('logout-page');
    (0, edit_user_1.defineEditUser)('edit-user');
    (0, home_page_1.defineHomePage)('home-page');
};
exports.defineViews = defineViews;
